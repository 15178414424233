<template>
  <div>
    <div class="Dealership">
      <!-- Dealership -->
      <div class="accordion" id="accordionExample31">
        <div class="card">
          <div class="card-header" id="headingOne31">
            <h2 class="mb-0">
              <button
                class="btn btn-link"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne31"
                aria-expanded="true"
                aria-controls="collapseOne31"
              >
                <div class="detais-title">
                  <h3>
                    <img
                      class="img-fluid arrow-down"
                      src="assets/img/arrow-down.svg"
                      alt=""
                    />Email Signature
                  </h3>
                </div>
              </button>
            </h2>
          </div>

          <div
            id="collapseOne31"
            class="collapse show"
            aria-labelledby="headingOne31"
            data-parent="#accordionExample31"
          >
            <div class="card-body">
              <div class="setting-tables">
                <form>
                  <editor
                    ref="tiny"
                    v-model="emailsign"
                    :api-key="tinykey"
                    :init="editorConfig"
                  ></editor>
                </form>
                <br /><br /><br />
                <div class="adddetais editarea">
                  <a @click="addSignature" class="add-appoi"> Update</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Editor from "@tinymce/tinymce-vue";
import axios from "axios";
import { tinymcekey } from "../../config.js";
export default {
  name: "EmailSignature",
  components: {
    editor: Editor,
  },
  data() {
    return {
      emailsign: "",
      tinykey: tinymcekey,
      editorConfig: {
        element_format: "html",
        relative_urls: false,
        remove_script_host: false,
        menubar: false,
        height: 300,
        branding: false,
       
        plugins: [
          "advlist autolink lists link image media  charmap print preview anchor",
          "searchreplace visualblocks code",
        ],
        toolbar:
          "bold italic | alignleft aligncenter alignright | bullist numlist | link  |image| |media | code | formatselect",
        images_upload_handler: function (blobInfo, success, failure, progress) {
          var xhr, formData;

          xhr = new XMLHttpRequest();
          xhr.withCredentials = false;
          //console.log(axios.defaults.baseURL+'tinyImageUpload')
          xhr.open("POST", axios.defaults.baseURL + "emialSignatureImage");

          xhr.upload.onprogress = function (e) {
            progress((e.loaded / e.total) * 100);
          };

          xhr.onload = function () {
            var json;

            if (xhr.status === 403) {
              failure("HTTP Error: " + xhr.status, { remove: true });
              return;
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              failure("HTTP Error: " + xhr.status);
              return;
            }

            json = JSON.parse(xhr.responseText);

            if (!json || typeof json.location != "string") {
              failure("Invalid JSON: " + xhr.responseText);
              return;
            }

            success(json.location);
          };

          xhr.onerror = function () {
            failure(
              "Image upload failed due to a XHR Transport error. Code: " +
                xhr.status
            );
          };
          
          formData = new FormData();
          formData.append("file", blobInfo.blob(), blobInfo.filename());

          xhr.send(formData);

          //console.log(blobInfo.base64())
          //console.log(success)
          //console.log(failure)
        },
    
       
      },
    };
  },
  created() {
    this.getSignature();
  },
  methods: {
    addSignature() {
      axios
        .post(
          "addSignature",
          { signature: this.emailsign },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            alert("Email Signature Added");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSignature() {
      axios
        .get("getEmailSign", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.emailsign = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.adddetais a.add-appoi {
  top: 90%;
}
</style>

